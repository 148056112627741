<template>
  <div
    v-if="title"
    class="hm-product-tag text-truncate text-nowrap smaller"
    :class="{ selected: selected }"
    v-html="sanitizedTitle"
    v-bind="$attrs"
  ></div>
  <div
    v-else
    class="hm-product-tag text-truncate text-nowrap smaller"
    :class="{ selected: selected }"
    v-bind="$attrs"
  >
    <slot></slot>
  </div>
</template>

<script>
import { sanitize } from '@/utils/utils';

export default {
  name: 'HMProductTag',
  props: {
    title: {
      type: String,
      required: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sanitizedTitle() {
      if (!this.title) return '';
      return sanitize(this.title);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.hm-product-tag {
  background-color: $lightest-gray;
  border-radius: 2rem;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.selected {
    background-color: $medium-gray;
    color: white;
  }
}
</style>
